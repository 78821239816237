// interfaces
import type {
    CompletedPhraseInterface,
    ConnectPhrases,
    ConnectWord,
    ConnectWordToImagesInterface,
    menuItems,
    OrderWordCompletedPhraseInterface,
    OrderWordImageInterface,
    OrderWordInterface,
    SelectedWordForQuestionInterface,
    SelectedWordImageInterface,
    SelectedWordInterface,
    TaskAssociateWordInterface,
} from '../modulesInterfaces';

export const initialDataTaskSelectedWord: SelectedWordInterface = {
    correct: '',
    file: '',
    id: '',
    options: [],
    subtitle: '',
    skip: '',
    target: 0,
    title: '',
    type: 'SELECTED_WORD',
};

export const initialDataTaskCompletedPhrase: CompletedPhraseInterface = {
    correct: '',
    id: '',
    file: '',
    fileImg: '',
    options: [],
    phraseEnglish: '',
    phrasePortuguese: '',
    subtitle: '',
    skip: '',
    target: 0,
    title: '',
    typeQuestion: 'Selecionar',
    type: 'COMPLETED_PHRASE',
};

export const initialDataTaskConnectPhrases: ConnectPhrases = {
    id: '',
    options: [],
    subtitle: '',
    title: '',
    skip: '',
    target: 0,
    type: 'CONNECT_PHRASES',
};

export const initialDataTaskOrderWordImage: OrderWordImageInterface = {
    id: '',
    order: '',
    phraseFinal: '',
    phraseInitial: '',
    subtitle: '',
    skip: '',
    title: '',
    type: 'ORDER_WORD_IMAGE',
    file: '',
    fileImg: '',
};

export const initialDataTaskOrderWord: OrderWordInterface = {
    id: '',
    order: '',
    phraseFinal: '',
    phraseInitial: '',
    subtitle: '',
    skip: '',
    title: '',
    type: 'ORDER_WORD',
    file: '',
};

export const initialDataTaskConnectWordToImages: ConnectWordToImagesInterface = {
    id: '',
    options: [],
    subtitle: '',
    skip: '',
    target: 0,
    title: '',
    type: 'CONNECT_WORD_TO_IMAGES',
};

export const initialDataTaskConnectWord: ConnectWord = {
    id: '',
    options: [],
    subtitle: '',
    skip: '',
    target: 0,
    title: '',
    type: 'CONNECT_WORD',
};

export const initialDataTaskSelectedWordImage: SelectedWordImageInterface = {
    correct: '',
    file: '',
    fileImg: '',
    id: '',
    options: [],
    subtitle: '',
    skip: '',
    target: 0,
    title: '',
    type: 'SELECTED_WORD_IMAGE',
};

export const initialDataTaskAssociateWord: TaskAssociateWordInterface = {
    id: '',
    subtitle: '',
    options: [],
    target: 0,
    skip: '',
    title: '',
    type: 'ASSOCIATE_WORD',
};

export const initialDataTaskOrderWordCompletedPhrase: OrderWordCompletedPhraseInterface = {
    id: '',
    file: '',
    answer: '',
    phraseInitial: '',
    phraseFinal: '',
    subtitle: '',
    skip: '',
    title: '',
    type: 'ORDER_WORD_COMPLETED_PHRASE',
};

export const initialDataTaskSelectedWordForQuestion: SelectedWordForQuestionInterface = {
    correct: '',
    file: '',
    id: '',
    options: [],
    subtitle: '',
    skip: '',
    target: 0,
    title: '',
    type: 'SELECTED_WORD_FOR_QUESTION',
    question: '',
};

export const allMenuItems: menuItems = {
    ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
